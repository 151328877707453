import { FC } from "react";
import { AnyHander } from "./types";

export type DoneDialogProps = {
	isWin: boolean,
	word: string,
	onCloseDialog: AnyHander,
	onStartNewGame: AnyHander,
};

export const DoneDialog: FC<DoneDialogProps> = ({ word, isWin: result, onCloseDialog, onStartNewGame }) => {
	return (
		<div className="dialog">
			<div className="dialog-bg" onClick={onCloseDialog} />
			<div className="dialog-win dialog-result">
				<div className="self-end">
					<button className="button" type="button" onClick={onCloseDialog}>
						<span className="material-symbols-outlined">
							close
						</span>
					</button>
				</div>
				<div className="flex grow flex-col pt-10 pb-10 justify-between">
					<div>
						<div className="text-xl text-center">{result ? 'Вы выйграли!' : 'Загаданное слово'}</div>
						<div className="text-3xl text-center font-bold uppercase ">{word}</div>
					</div>
					<div className="flex flex-col items-center">
						<button className="text-lg" type="button" onClick={onStartNewGame}>Ещё раз</button>
					</div>
				</div>
			</div>
		</div>
	);
};