import { Fragment } from "react/jsx-runtime";
import { GameArea } from "./Area";
import { DoneDialog } from "./DoneDialog";
import { Keyboard, useKeyboardListener } from "./Keyboard";
import { SettingsDialog } from "./SettingDialog";
import { useSettingDialog, useWordleGameSettings } from "./settings";
import { useWordleGame } from "./Game";
import { createContext } from "react";

export const SizeContext = createContext(6);

export const App: React.FC = () => {
	const { settings, saveSettings } = useWordleGameSettings();
	const { settingsDialog, openSettingDialog, closeSettingDialog } = useSettingDialog(saveSettings);
	const {
		game,
		gameResult,
		word,
		resultDialodHidden,
		error,
		restartGame,
		hideResultDialod,
		keyPressed,
		onAnimationDone
	} = useWordleGame(settings);
	useKeyboardListener(keyPressed);

	if (!settings || !word) {
		return <Fragment />;
	}

	return (
		<SizeContext.Provider value={settings.wordSize}>
			<div className="body">
				<div className="flex">
					<div className="p-3">
						<button className="button" type="button" onClick={restartGame}>
							<span className="material-symbols-outlined">
								refresh
							</span>
						</button>
					</div>
					<div className="flex grow items-center">
						<div className="w-full text-center text-2xl font-bold uppercase ">
							Угадай слово
						</div>
					</div>
					<div className="p-3">
						<button className="button" type="button" onClick={openSettingDialog}>
							<span className="material-symbols-outlined">
								settings
							</span>
						</button>
					</div>
				</div>
				<div style={{ flexGrow: '1', position: 'relative' }} className="game">
					<div className="flex flex-col" style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}>
						<div className="flex flex-col flex-warp basic-0 overflow-y-auto overflow-x-hidden items-center">
							<div className="flex flex-col game-container">
								<GameArea game={game} onAnimationDone={onAnimationDone} wordSize={settings.wordSize} />
							</div>
						</div>
					</div>
				</div>
				{error && <div className="text-center text-red-500 text-xl font-bold">Слово не найдено!</div>}
				<div><Keyboard onKeyPress={keyPressed} words={game.words} /></div>
				{settingsDialog && <SettingsDialog settings={settings} onCloseSettingsDialog={closeSettingDialog} onSettingsSave={(args) => { saveSettings(args); closeSettingDialog(); }} />}
				{gameResult !== undefined && !resultDialodHidden && <DoneDialog word={word} isWin={gameResult} onStartNewGame={restartGame} onCloseDialog={hideResultDialod} />}
			</div>
		</SizeContext.Provider>
	);
}
