import { useCallback, useState } from "react";
import { WordleSettings } from "./settings";
import { AnyHander, PromiseMaybe } from "./types";

export type SettingsDialogProps = {
	settings: WordleSettings,
	onCloseSettingsDialog: AnyHander,
	onSettingsSave: (settings: WordleSettings) => PromiseMaybe,
}

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ settings, onCloseSettingsDialog, onSettingsSave }) => {
	const [wordSize, setWordSize] = useState(settings.wordSize);
	const [attempts, setAttempts] = useState(settings.attempts);
	const saveSettingsCb = useCallback(() => {
		onSettingsSave({ wordSize, attempts })
	}, [wordSize, attempts, onSettingsSave]);

	const setWordSizeCb = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		setWordSize(Number(evt.target.value));
	}, [setWordSize]);
	const setAttemptsCb = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		setAttempts(Number(evt.target.value));
	}, [setAttempts]);

	return (
		<div className="dialog">
			<div className="dialog-bg" onClick={onCloseSettingsDialog} />
			<div className="dialog-win w-[300px]">
				<div className="self-end">
					<button className="button" type="button" onClick={onCloseSettingsDialog}>
						<span className="material-symbols-outlined">
							close
						</span>
					</button>
				</div>
				<div>
					<div className="mt-2">
						<div className="text-2xl">Размер слова</div>
						<div className="flex">
							<input className="grow mr-4" type="range" min="3" max="20" value={wordSize} onChange={setWordSizeCb} />
							<input className="text-center text-xl w-[30px]" type="text" readOnly value={wordSize} />
						</div>
					</div>
					<div className="mt-2">
						<div className="text-2xl">Количество попыток</div>
						<div className="flex">
							<input className="grow mr-4" type="range" min="3" max="40" value={attempts} onChange={setAttemptsCb} />
							<input className="text-center text-xl w-[30px]" type="text" readOnly value={attempts} />
						</div>
					</div>
				</div>
				<div className="mt-4 self-end">
					<button className="text-2xl" type="button" onClick={saveSettingsCb}>Сохранить</button>
				</div>
			</div>
		</div>
	)
};
