import { useCallback, useEffect, useState } from "react";
import { api } from "./api";
import { AnyHander } from "./types";

export const useSettingDialog = (saveSettings: (settings: WordleSettings) => Promise<void>): SettingDialogHook => {
	const [settingsDialog, setSettingsDialog] = useState(false);

	const saveSettingsOnDialog = useCallback(async (settings: WordleSettings) => {
		await saveSettings(settings);
		setSettingsDialog(false);
	}, [saveSettings, setSettingsDialog]);

	return {
		settingsDialog,
		openSettingDialog: () => { setSettingsDialog(true); },
		closeSettingDialog: () => { setSettingsDialog(false); },
		saveSettingsOnDialog,
	}
};

export const useWordleGameSettings = (): WordleGameSettingsHook => {
	const [settings, setSettings] = useState<WordleSettings | undefined>(undefined);

	useEffect(() => {
		(async () => {
			setSettings(await api.settings.get());
		})()
	}, []);

	const saveSettings = useCallback(async (next: WordleSettings) => {
		await api.settings.save(next);
		setSettings(next);
	}, [setSettings]);

	return {
		settings,
		saveSettings,
	}
};

type WordleGameSettingsHook = {
	settings: WordleSettings | undefined,
	saveSettings: (settings: WordleSettings) => Promise<void>,
};

export type SettingDialogHook = {
	settingsDialog: boolean,
	openSettingDialog: AnyHander,
	closeSettingDialog: AnyHander,
	saveSettingsOnDialog: (settings: WordleSettings) => Promise<void>,
}

export type WordleSettings = {
	wordSize: number,
	attempts: number,
};