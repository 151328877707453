import { WordleSettings } from "./settings";

const loadSettings = (): WordleSettings => {
	const saved = localStorage.getItem('settings');
	if (saved) {
		return JSON.parse(saved);
	}
	return {
		attempts: 5,
		wordSize: 5,
	}
}

const saveSettings = (settings: WordleSettings) => {
	localStorage.setItem('settings', JSON.stringify(settings));
}

const getRandom = async (size: number) => {
	// return 'прива'
	const res = await fetch(`/api/getWord?len=${size}`);
	return res.text();
}

const getSuggestion = async (word: string) => {
	// return new Promise<boolean>(r => setTimeout(() => r(true), 1000));
	const res = await fetch(`/api/checkWord?word=${word}`);
	return (await res.text()) === '1';
}

export const api = {
	word: {
		getRandom: (size: number) => getRandom(size),
		check: (suggestion: string) => getSuggestion(suggestion),
	},
	settings: {
		get: () => loadSettings,
		save: (se: WordleSettings) => { saveSettings(se) },
	}
};
