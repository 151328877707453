import { cloneDeep, range } from "lodash";
import { useCallback, useEffect, useState } from "react"
import { WordleSettings } from "./settings";
import { WordAnimation, WordleGame, WordleWord } from "./Area";
import { AnyHander } from "./types";
import { api } from "./api";
import { CardProps } from "./Card";

type WordleGameHook = {
	game: WordleGame,
	restartGame: AnyHander,
	keyPressed: (key: string) => AnyHander,
	onAnimationDone: AnyHander,
	gameResult: boolean | undefined,
	word: string | undefined,
	resultDialodHidden: boolean,
	hideResultDialod: AnyHander,
	error: boolean,
};

export const useWordleGame = (settings: WordleSettings | undefined): WordleGameHook => {
	const [word, setWord] = useState<string | undefined>(undefined);
	const [line, setLine] = useState(0);
	const [symbol, setSymbol] = useState(0);

	const [animation, setAnimation] = useState<WordAnimation | undefined>(undefined);
	const [words, setWords] = useState<WordleWord[]>([]);
	const [onAnimationDone, setOnAnimationDone] = useState<{ fn: AnyHander }>({ fn: () => { } });

	const [gameResult, setGameResult] = useState<boolean | undefined>(undefined);
	const [resultDialodHidden, setResultDialodHidden] = useState(false);

	const [error, setError] = useState(false);


	const startGame = useCallback(async () => {
		if (!settings) {
			return;
		}
		setWord(undefined);
		setGameResult(undefined);
		setResultDialodHidden(false);
		const word = await api.word.getRandom(settings.wordSize);
		const words = range(settings.attempts).map(() => {
			return range(settings.wordSize).map(() => {
				return {
					variant: 'empty' as const,
					value: '',
				}
			});
		});
		setWord(word);
		setLine(0);
		setSymbol(0);
		setAnimation(undefined);
		setWords(words);
	}, [setWord, setLine, setSymbol, setAnimation, setWords, settings]);

	useEffect(() => {
		startGame();
	}, [startGame, settings]);

	const restartGame = useCallback(
		() => { startGame(); },
		[startGame]
	);
	const keyPressed = useCallback((key: string) => () => {
		if (!word || animation || gameResult !== undefined) {
			return;
		}

		const onAnimationComplite = (animation: WordAnimation) => {
			if (animation.target.every(s => s.variant === 'correct')) {
				setGameResult(true);
				return;
			}
			if (line >= words.length - 1) {
				setGameResult(false);
				return;
			}

			setWords(w => {
				const arr = cloneDeep(w);
				arr[line] = cloneDeep(animation.target);
				return arr;
			})
			setLine(l => l + 1);
			setAnimation(undefined);
			setSymbol(0);
		};

		const compliteWord = async () => {
			if (words[line].length !== symbol) {
				return;
			}
			const suggestion = words[line].map(s => s.value).join('');
			if (!(await api.word.check(suggestion))) {
				setError(true);
				setTimeout(() => {
					setError(false)
				}, 2000);
				setWords(w => {
					const arr = cloneDeep(w);
					console.log(arr[line], arr[line+1])
					arr[line] = arr[line].map(() => ({
						variant: 'empty' as const,
						value: '',
					}));
					return arr;
				});
				setSymbol(0);
				return;
			}
			let animationWord: Array<CardProps | undefined> = new Array(words[line].length);
			const arr: Array<string | undefined> = word!.split('');
			words[line].forEach((symbol, i) => {
				if (symbol.value !== arr[i]) {
					return;
				}
				arr[i] = undefined;
				animationWord[i] = {
					value: symbol.value,
					variant: 'correct',
				};

			});
			words[line].forEach((symbol, i) => {
				if (animationWord[i]) {
					return;
				}
				const idx = arr.findIndex(v => symbol.value === v);
				if (idx === -1) {
					return;
				}
				animationWord[i] = {
					variant: 'half',
					value: symbol.value,
				}
				arr[idx] = undefined;
			});
			words[line].forEach((symbol, i) => {
				if (animationWord[i]) {
					return;
				}
				animationWord[i] = {
					variant: 'wrong',
					value: symbol.value,
				}
			});
			const animation = {
				index: line,
				target: animationWord as CardProps[],
			};
			setAnimation(animation);
			setOnAnimationDone({ fn: () => onAnimationComplite(animation) });
		};

		const removeSymbol = () => {
			const prevSymbol = symbol - 1;
			if (prevSymbol >= 0) {
				setWords(words => {
					const arr = cloneDeep(words);
					arr[line][prevSymbol] = {
						variant: 'empty' as const,
						value: '',
					};
					return arr;
				});
				setSymbol(s => s - 1);
			}
		};

		const addSymbol = () => {
			if (words[line].length > symbol) {
				setWords(words => {
					const arr = cloneDeep(words);
					arr[line][symbol] = {
						variant: 'init' as const,
						value: key,
					};
					return arr;
				});
				setSymbol(s => s + 1);
			}
		};

		if (key === 'enter') {
			compliteWord();
		}
		if (key === 'backspace') {
			removeSymbol();
		}
		if (/[а-я]/.test(key)) {
			addSymbol();
			// @ts-expect-error
			document.querySelector(`div[data-line-num="${line}"]`)?.scrollIntoViewIfNeeded?.(false);
		}
	}, [animation, line, symbol, word, words, gameResult]);

	const hideResultDialod = useCallback(() => {
		setResultDialodHidden(true);
	}, []);

	const game: WordleGame = {
		animation,
		words,
	};

	return {
		game,
		restartGame,
		keyPressed,
		onAnimationDone: onAnimationDone.fn,
		gameResult,
		word,
		resultDialodHidden,
		error,
		hideResultDialod,
	};
};
