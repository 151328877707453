import { ReactElement, useEffect, useRef, useState } from "react";
import { AnyHander } from "./types";

type FlipProps = {
	front: ReactElement,
	back: ReactElement,
	flipped: boolean,
	onFlipEnd: AnyHander,
	size: number,
};

export const Flip: React.FC<FlipProps> = ({ front, back, flipped, onFlipEnd, size }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [result, setResult] = useState(false);

	useEffect(() => {
		const element = ref.current!;
		const cb = () => {
			setResult(true);
			onFlipEnd();
		}
		element.addEventListener('transitionend', cb);
		return () => {
			element.removeEventListener('transitionend', cb);
		};
	}, [onFlipEnd, ref]);

	useEffect(() => {
		if (!flipped) {
			return;
		}
		setTimeout(() => {
			if (!result) {
				onFlipEnd();
			}
		}, 1000)
	}, [flipped]);

	return (
		<div className={`flip-card ${flipped ? 'flip-card-hover' : ''}`} style={{ width: `${size}px`, height: `${size}px`, perspective: `${size * 2}px` }}>
			<div className="flip-card-inner" ref={ref}>
				<div className="flip-card-front">
					{front}
				</div>
				<div className="flip-card-back">
					{back}
				</div>
			</div>
		</div>
	)
}