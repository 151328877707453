import { useCallback, useEffect } from "react";
import { WordleWord } from "./Area";
import { AnyHander, KeyPressedCb, KeyboardListenerHook } from "./types";

export type KeyboardProps = {
	onKeyPress: (char: string) => AnyHander,
	words: WordleWord[],
}

export const Keyboard: React.FC<KeyboardProps> = ({ onKeyPress, words }) => {
	const line1 = 'й ц у к е н г ш щ з х ъ'.split(' ');
	const line2 = 'ф ы в а п р о л д ж э'.split(' ');
	const line3 = 'я ч с м и т ь б ю'.split(' ');

	const buttonVariantClass = useCallback((char: string) => {
		let bestClass = '';
		for (const word of words) {
			for (const letter of word) {
				if (letter.value === char && letter.variant === 'correct') {
					bestClass = 'correct';
				}
				if (letter.value === char && letter.variant === 'half' && bestClass !== 'correct') {
					bestClass = 'half';
				}
				if (letter.value === char && letter.variant === 'wrong' && bestClass === '') {
					bestClass = 'wrong';
				}
			}
		}
		return `key-button-${bestClass}`;
	}, [words]);

	return (
		<div className="mb-4">
			<div className="flex mt-3">
				{line1.map(char => (
					<button key={char} className={`key-button ${buttonVariantClass(char)}`} onClick={onKeyPress(char)}>{char}</button>
				))}
			</div>
			<div className="flex mt-3">
				{line2.map(char => (
					<button key={char} className={`key-button ${buttonVariantClass(char)}`} onClick={onKeyPress(char)}>{char}</button>
				))}
			</div>
			<div className="flex mt-3">
				<button className="key-button" onClick={onKeyPress('backspace')}>
					<span className="material-symbols-outlined">
						backspace
					</span>
				</button>
				{line3.map(char => (
					<button key={char} className={`key-button ${buttonVariantClass(char)}`} onClick={onKeyPress(char)}>{char}</button>
				))}
				<button className="key-button" onClick={onKeyPress('enter')}>
					<span className="material-symbols-outlined">
						keyboard_return
					</span>
				</button>
			</div>
		</div>
	);
};

export const useKeyboardListener = (keyPressed: KeyPressedCb): KeyboardListenerHook => {
	const keyDownCb = useCallback((evt: KeyboardEvent) => {
		keyPressed(evt.key.toLocaleLowerCase())();
	}, [keyPressed]);
	useEffect(() => {
		document.addEventListener('keydown', keyDownCb);

		return () => {
			document.removeEventListener('keydown', keyDownCb);
		};
	}, [keyDownCb]);
};
