export type CardProps = {
	variant: 'empty' | 'init' | 'half' | 'correct' | 'wrong',
	value: string,
};

export const Card: React.FC<CardProps & {size: number}> = ({ value, variant, size }) => {
	let classVariant = ''

	if (variant === 'half') {
		classVariant = 'card-half';
	} else if (variant === 'correct') {
		classVariant = 'card-correct';
	} else if (variant === 'wrong') {
		classVariant = 'card-wrong';
	}



	return (
		<div className={`card ${classVariant}`} style={{ width: `${size}px`, height: `${size}px`, fontSize: `${size * 0.9}px` }}>
			<div>
				{value}
			</div>
		</div>
	);
}
