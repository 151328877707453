import { useEffect, useState } from "react";
import { AnyHander } from "./types";
import { Flip } from "./Flip";
import { Card, CardProps } from "./Card";

export type WordAnimation = {
	index: number,
	target: WordleWord,
};

export type WordleSymbol = {
	variant: 'empty' | 'init' | 'half' | 'correct' | 'wrong',
	value: string,
};

export type WordleWord = WordleSymbol[];

export type WordleGame = {
	animation: WordAnimation | undefined,
	words: WordleWord[],
};

export type GameAreaProps = {
	game: WordleGame,
	onAnimationDone: AnyHander,
	wordSize: number,
}

export const GameArea: React.FC<GameAreaProps> = ({ game, wordSize, onAnimationDone }) => {
	const width = document.querySelector('html')!.offsetWidth;
	const fullSize = Math.round((width - 50) / (wordSize * 1.2));
	const size = fullSize < 50 ? fullSize : 50;

	if (!game.animation) {
		return <>{
			game.words.map((word, idx) => (
				<WordLine key={idx} word={word} num={idx} size={size} />
			))}
		</>;
	}

	return (
		<div>
			{game.words.map((word, idx) => {
				if (idx !== game.animation!.index) {
					return <WordLine key={idx} word={word} num={idx} size={size} />;
				}

				return <AnimationWordLine key={idx} from={word} to={game.animation!.target} onDone={onAnimationDone} size={size} />;
			})}
		</div>
	);
};

type WordLineProps = {
	word: CardProps[],
	num: number,
	size: number
}

const WordLine: React.FC<WordLineProps> = ({ word, num, size }) => (
	<div className='flex' data-line-num={num}>
		{word.map((card, idx) => (
			<div key={idx} style={{padding: `${size * 0.1}px`}}>
				<Card {...card} size={size} />
			</div>
		))}
	</div>
)

type AnimationWordLineProps = {
	from: CardProps[],
	to: CardProps[],
	size: number,
	onDone: AnyHander,
};

const AnimationWordLine: React.FC<AnimationWordLineProps> = ({ from, to, size, onDone }) => {
	const [flipped, setFlipped] = useState<true[]>([]);
	const done = (idx: number) => () => {
		setFlipped(flipped => { const arr = [...flipped]; arr[idx + 1] = true; return arr; });
	}

	useEffect(() => {
		if (flipped.length > from.length) {
			onDone();
		}
	}, [flipped, from, onDone]);

	useEffect(() => {
		setTimeout(() => {
			setFlipped([true]);
		}, 0);
	}, [])

	return (
		<div className="flex">
			{from.map((cardFrom, idx) => (
				<div key={idx} style={{padding: `${size * 0.1}px`}}>
					<Flip
						front={<Card {...cardFrom} size={size} />}
						back={<Card {...to[idx]} size={size} />}
						flipped={flipped[idx]}
						onFlipEnd={done(idx)}
						size={size}
					/>
				</div>
			))}
		</div>
	)
};
